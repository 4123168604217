@import url(https://fonts.googleapis.com/css?family=Roboto:400,900);




.soldOut {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 50px;
  color: white;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  /* right:50%;
  bottom:50%; */
  /* font-size: 2rem; */
  transform: translate(-50%, -50%);
  color:#007bff
  
}

